import React, { useEffect, useState } from "react";
import app_config from "../../config";
import ChatListItemCard from "./ChatListItemCard";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ChatList({ selectedUser, changeChat }) {
  const [selectedChat, setSelectedChat] = useState();
  const [contacts, setContacts] = useState([]);

  useEffect(() => {
    const getUserById = async () => {
      let currentUser =
        JSON.parse(sessionStorage.getItem("investor")) ||
        JSON.parse(sessionStorage.getItem("startup"));
      const response = await fetch(
        `${app_config.apiurl}/chat/getchats/${currentUser._id}`,
      );
      const data = await response.json();
      let contacts = data.contacts;
      console.log("Selected USer", selectedUser);
      if (selectedUser) {
        let index = data.contacts.findIndex(
          (val) => val._id === selectedUser._id,
        );
        contacts = index > -1 ? contacts : [...contacts, selectedUser];
        setSelectedChat(index > -1 ? index : contacts.length - 1);
      }
      setContacts(contacts);
    };
    getUserById();
  }, [selectedUser]);

  const changeCurrentChat = (index, chat) => {
    setSelectedChat(index);
    changeChat(chat);
  };

  return (
    <>
      <ul className="overflow-auto h-[66vh] md:h-[61vh] scrollbar-custom ">
        <h2 className="my-2 mb-2 ml-2 text-gray-900 dark:text-white">Chats</h2>
        <li>
          {contacts.map((contact, index) => (
            <div
              key={index}
              style={{ borderColor: "rgb(229 231 235)" }}
              className={classNames(
                index === selectedChat
                  ? "bg-chatbackground dark:bg-gray-700"
                  : "transition duration-150 ease-in-out cursor-pointer bg-white border-b border-gray-200 hover:bg-gray-100 dark:bg-gray-900 dark:border-gray-700 dark:hover:bg-gray-700",
                "flex items-center px-3 py-2 text-sm",
              )}
              onClick={() => changeCurrentChat(index, contact)}
            >
              <ChatListItemCard user={contact} />
            </div>
          ))}
        </li>
      </ul>
    </>
  );
}
