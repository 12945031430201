import React from "react";
import "./about.css";
import Header from "./Header";
import HeaderNew from "./HeaderNew";
import ObjectiveDetail from "./ObjectiveDetail";

const About = () => {
  return (
    <>
      <HeaderNew />

      <div style={{ height: "100%" }}>
        <div
          className="row justify-content-center p-5"
          style={{ height: "40vh", backgroundColor: "#b0ff4b" }}
        >
          <h1 className="text-center text-black mt-10 text-4xl">
            About Kreedify
          </h1>
        </div>

        <div
          className="row justify-content-center mx-auto"
          style={{ marginTop: "-140px", height: "fit-content", width: "75%" }}
        >
          <div
            className="col-md-3 p-4 mx-3 my-4 card justify-content-center align-items-center"
            style={{ backgroundColor: "white", width: "300px" }}
          >
            <div className="row ">
              <div className="col-md-3 mb-2">
                <img
                  src="https://cdn-icons-png.flaticon.com/512/3133/3133430.png"
                  alt=""
                  style={{ width: "50px" }}
                />
              </div>
              <div className="col-md-9">
                <p className="text-black">
                  Connecting startups <br></br>with scouts & other professionals
                  with investor network
                </p>
              </div>
            </div>
          </div>
          <div
            className="col-md-3 p-4  mx-3 my-4 card justify-content-center align-items-center bg-white"
            style={{ width: "300px" }}
          >
            <div className="row">
              <div className="col-md-3 mb-2">
                <img
                  src="https://cdn-icons-png.flaticon.com/512/9576/9576835.png"
                  alt=""
                  style={{ width: "50px" }}
                />
              </div>
              <div className="col-md-9">
                <p className="text-black bg-white">
                  {" "}
                  Connecting scouts <br></br>with interesting <br></br>startups
                </p>
              </div>
            </div>
          </div>
          <div
            className="col-md-3  p-4  mx-3 my-4 card  justify-content-center align-items-center"
            style={{ backgroundColor: "white", width: "300px" }}
          >
            <div className="row">
              <div className="col-md-3 mb-2">
                <img
                  src="https://cdn-icons-png.flaticon.com/512/7656/7656523.png"
                  alt=""
                  style={{ width: "50px" }}
                />
              </div>
              <div className="col-md-9 text-black">
                <p>
                  Helping Scouts build<br></br> their dealflow for <br></br>
                  their VC fund
                </p>
              </div>
            </div>
          </div>
          <div
            className="col-md-3 p-4  mx-3 my-4 card  justify-content-center align-items-center bg-white"
            style={{ width: "300px" }}
          >
            <div className="row">
              <div className="col-md-3 mb-2">
                <img
                  src="https://cdn-icons-png.flaticon.com/512/1651/1651707.png"
                  alt=""
                  style={{ width: "50px" }}
                />
              </div>
              <div className="col-md-9">
                <p className="text-black bg-white">
                  Amplifying founder <br></br>
                  efforts to fundraise & <br></br>
                  network with scouts <br></br>
                  or investment <br></br>
                  professionals
                </p>
              </div>
            </div>
          </div>

          <div
            className="col-md-3 p-4  mx-3 my-4 card justify-content-center align-items-center"
            style={{ backgroundColor: "white", width: "300px" }}
          >
            <div className="row">
              <div className="col-md-3 mb-2">
                <img
                  src="https://cdn-icons-png.flaticon.com/512/4570/4570180.png"
                  alt=""
                  style={{ width: "50px" }}
                />
              </div>
              <div className="col-md-9 text-black">
                <p>
                  Creating a large <br></br>
                  ecosystem of more <br></br>
                  stakeholders in the <br></br>
                  near future
                </p>
              </div>
            </div>
          </div>
          <div
            className="col-md-3 p-4  mx-3 my-4 card justify-content-center align-items-center"
            style={{
              display: "none",
              backgroundColor: "#fea5c0",
              width: "300px",
            }}
          >
            <div className="row">
              <div className="col-md-3 mb-2">
                <img
                  src="https://cdn-icons-png.flaticon.com/512/3251/3251393.png"
                  alt=""
                  style={{ width: "50px" }}
                />
              </div>
              <div className="col-md-9">
                <p>
                  Your gateway to growth <br />
                  Get access to valuable services & connect with a world of
                  startup opportunities and investor services
                </p>
              </div>
            </div>
          </div>
          <div
            className="col-md-3  p-4 mx-3 my-4 card  justify-content-center align-items-center"
            style={{
              display: "none",
              backgroundColor: "white",
              width: "300px",
            }}
          >
            <div className="row">
              <div className="col-md-3 mb-2">
                <img
                  src="https://cdn-icons-png.flaticon.com/512/2498/2498074.png"
                  style={{ width: "50px" }}
                  alt=""
                />
              </div>
              <div className="col-md-9">
                <p>
                  Launch your startups to new heights <br />
                  Access powerful business services & connect with investors on
                  our platform today
                </p>
              </div>
            </div>
          </div>
          <div
            className="col-md-3 p-4  mx-3 my-4 card  justify-content-center align-items-center"
            style={{
              display: "none",
              backgroundColor: "#fea5c0",
              width: "300px",
            }}
          >
            <div className="row">
              <div className="col-md-3 mb-2">
                <img
                  src="https://cdn-icons-png.flaticon.com/512/5333/5333243.png"
                  style={{ width: "50px" }}
                  alt=""
                />
              </div>
              <div className="col-md-9">
                <p>
                  Balanced deals, greater opportunities <br />
                  Let us be the weighing scale between investors & startups for
                  a successful partnership!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ObjectiveDetail />
    </>
  );
};

export default About;
