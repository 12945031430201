import React from "react";
import { Link } from "react-router-dom";
import AlphabetAvatar from "../AlphabetAvatar";

const ConnectionRequests = ({
  connectionRequests,
  handleConnectionRequest,
}) => {
  const isAvatarImageAvailable = (imageUrl) => {
    console.log("imageUrl:" + imageUrl);
    return (
      imageUrl !== undefined &&
      imageUrl !== null &&
      imageUrl !== "undefined" &&
      imageUrl.trim().length > 0
    );
  };

  return (
    <div className="py-2 px-2 md:-mt-8 md:!py-10 md:!px-36">
      {connectionRequests.length === 0 ? (
        <p className="text-center mt-4 mb-4">
          No more connection requests available.
        </p>
      ) : (
        connectionRequests.map((request) => (
          <div
            key={request._id}
            className="flex flex-col w-[18rem] md:w-[40rem] gap-2 py-8 sm:p-6 bg-white mx-12 md:-mx-24 rounded-xl shadow-md mb-3 md:mb-5"
          >
            <div className="flex flex-col sm:flex-row justify-center sm:justify-between items-center sm:items-start">
              <div className="flex flex-col md:flex-row items-center">
                <div className="flex flex-col items-center mb-3 md:mb-4 sm:mb-0">
                  {isAvatarImageAvailable(request.sender.startupimage) ? (
                    <img
                      className="h-16 w-16 rounded-full"
                      src={request.sender.startupimage}
                      alt="avatar"
                    />
                  ) : (
                    <AlphabetAvatar alphabet={request.sender.name.charAt(0)} />
                  )}
                </div>
                <div className="md:ml-8 flex flex-col items-center md:items-start">
                  <h2 className="text-2xl font-medium capitalize md:ml-0 truncate text-center md:text-left">
                    {request.sender.name}
                  </h2>
                  <div className="mt-2 flex justify-center md:justify-start">
                    <Link
                      className="bg-white border-2 border-black text-black p-2 rounded"
                      to={"/investor/startupdetails/" + request.sender._id}
                    >
                      View Profile
                    </Link>
                  </div>
                </div>
              </div>
              <div className="flex flex-col sm:flex-row items-center mt-4 md:mt-4 space-x-10 md:space-x-4 space-y-2 sm:space-y-0 w-full sm:w-auto">
                <div className="flex justify-center w-full">
                  <button
                    className="bg-skincolor text-black px-2  py-1  rounded w-20 h-10 md:w-20 md:h-10 sm:w-auto sm:h-auto"
                    onClick={() => handleConnectionRequest(request._id, true)}
                  >
                    Accept
                  </button>
                  <button
                    className="bg-deny text-black px-2 md:px-4 py-1 md:py-2 rounded w-20 h-10 md:w-20 md:h-10 sm:w-auto sm:h-auto ml-2"
                    onClick={() => handleConnectionRequest(request._id, false)}
                  >
                    Deny
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default ConnectionRequests;
