import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import app_config from "../../config";
import ChatForm from "./ChatForm";
import ChatListItemCard from "./ChatListItemCard";
import Message from "./Message";
import { io } from "socket.io-client";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

export default function ChatRoom({ user, onBack }) {
  // const scrollRef = useRef();
  const navigate = useNavigate();
  const [messages, setMessages] = useState([]);
  const currentUser =
    JSON.parse(sessionStorage.getItem("investor")) ||
    JSON.parse(sessionStorage.getItem("startup"));
  const [socket, setSocket] = useState(
    io(app_config.apiurl, { autoConnect: false }),
  );
  useEffect(() => {
    const fetchChats = async () => {
      const res = await fetch(
        `${app_config.apiurl}/chat/getchat/${currentUser._id}/${user._id}`,
      );
      const chatsData = await res.json();
      console.log(chatsData);
      setMessages(chatsData.result);
    };
    socket.connect();
    socket.emit("addtocontact", currentUser._id);
    fetchChats();
  }, [currentUser._id, user._id]);

  socket.on("recmsg", (data) => {
    setMessages([...messages, data]);
  });

  const handleFormSubmit = async (message) => {
    if (!message.trim()) return;
    const formData = {
      users: [currentUser._id, user._id],
      sentBy: currentUser._id,
      date: new Date(),
      to: user._id,
      message: message,
      name: currentUser.name,
    };

    socket.emit("sendmsg", formData);
    setMessages([...messages, formData]);

    const res = await fetch(`${app_config.apiurl}/chat/add`, {
      method: "POST",
      body: JSON.stringify(formData),
      headers: { "Content-Type": "application/json" },
    });
    console.log(res.status);
  };

  const handleCardClick = () => {
    if (user.role === "investor") {
      navigate(`/startup/investordetails/${user._id}`);
    } else {
      navigate(`/investor/startupdetails/${user._id}`);
    }
  };

  return (
    <div className="lg:col-span-2 lg:block h-[79vh] flex flex-col md:h-[72vh]">
      {" "}
      <div className="w-full h-full flex flex-col">
        <div
          className="p-3 bg-white border-b border-gray-200 dark:bg-gray-900 dark:border-gray-700 flex items-center"
          style={{ borderColor: "rgb(229 231 235)" }}
        >
          <button className="block md:hidden mr-4" onClick={onBack}>
            <ArrowBackIosIcon />
          </button>
          <div onClick={handleCardClick} className="cursor-pointer">
            <ChatListItemCard user={user} />
          </div>
        </div>

        <div
          className="flex-1 w-full max-h-[64vh] md:max-h-[23rem] xl:max-h-[30rem] p-6 overflow-y-auto overflow-x-hidden scrollbar-custom bg-chatbackground border-b border-gray-200 dark:bg-gray-900"
          style={{ borderColor: "rgb(229 231 235)" }}
        >
          <ul className="space-y-2">
            {messages.map((message, index) => (
              <div key={index}>
                <Message message={message} self={currentUser._id} />
              </div>
            ))}
            {/* <div ref={scrollRef} /> */}
          </ul>
        </div>

        <div className="w-full">
          <ChatForm handleFormSubmit={handleFormSubmit} />
        </div>
      </div>
    </div>
  );
}
