import React from "react";
import HeaderNew from "./HeaderNew";

const FaqSection = () => {
  return (
    <>
      <HeaderNew />
      
      <div
        className="justify-content-center align-items-center row mb-8"
        style={{ height: "80vh" }}
      >
        <div className="mx-auto" style={{ width: "70%" }}>
          <div className="accordion w-100" id="basicAccordion">
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingOne">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-mdb-toggle="collapse"
                  data-mdb-target="#basicAccordionCollapseOne"
                  aria-expanded="false"
                  aria-controls="collapseOne"
                >
                  How Kreedify help Startups ?
                </button>
              </h2>
              <div
                id="basicAccordionCollapseOne"
                className="accordion-collapse collapse"
                aria-labelledby="headingOne"
                data-mdb-parent="#basicAccordion"
                style={{}}
              >
                <div className="accordion-body">
                  Kreedify provides financial assistance to startups for proof
                  of concept, prototype development, product trials,
                  market-entry, and commercialization. Eligible startups can
                  apply for seed funding on the Kreedify. To provide easy access
                  to capital for early-stage entrepreneurs and startups with
                  innovative ideas and to offer a range of seed funding options,
                  and equity investments, to support proof of concept trials.
                  The Seed Funding will be disbursed to startups through
                  eligible investors across India.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingTwo">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-mdb-toggle="collapse"
                  data-mdb-target="#basicAccordionCollapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  Who can apply for seed funding ?
                </button>
              </h2>
              <div
                id="basicAccordionCollapseTwo"
                className="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-mdb-parent="#basicAccordion"
                style={{}}
              >
                <div className="accordion-body">
                  A recognized startup, incorporated not more than 2 years ago
                  at the time of application is invited to apply here. Detailed
                  eligibility criteria can be found at
                  <link></link>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingSeven">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-mdb-toggle="collapse"
                  data-mdb-target="#basicAccordionCollapseSeven"
                  aria-expanded="false"
                  aria-controls="collapseSeven"
                >
                  How can starup apply for funding ?
                </button>
              </h2>
              <div
                id="basicAccordionCollapseSeven"
                className="accordion-collapse collapse"
                aria-labelledby="headingSeven"
                data-mdb-parent="#basicAccordion"
                style={{}}
              >
                <div className="accordion-body">
                  Startups submit anapplication form and make their profiles
                  visible on our website so that potential investors may get in
                  touch with them .
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingThree">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-mdb-toggle="collapse"
                  data-mdb-target="#basicAccordionCollapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  How much seed funding can a startup receive ?
                </button>
              </h2>
              <div
                id="basicAccordionCollapseThree"
                className="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-mdb-parent="#basicAccordion"
                style={{}}
              >
                <div className="accordion-body">
                  Seed Fund to an eligible startup by the incubator shall be
                  disbursed as follows: Up to Rs. 5 Lakhs as grant for
                  validation of Proof of Concept, or prototype development, or
                  product trials. The grant shall be disbursed in
                  milestone-based installments. These milestones can be related
                  to development of prototype, product testing, building a
                  product ready for market launch, etc. Up to Rs. 10 Lakhs of
                  investment for market entry, commercialization, or scaling up
                  through convertible debentures or debt or debt-linked
                  instruments
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingSix">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-mdb-toggle="collapse"
                  data-mdb-target="#basicAccordionCollapseSix"
                  aria-expanded="false"
                  aria-controls="collapseSix"
                >
                  Can I apply for the funding as an individual , or do I need a
                  team?
                </button>
              </h2>
              <div
                id="basicAccordionCollapseSix"
                className="accordion-collapse collapse"
                aria-labelledby="headingSix"
                data-mdb-parent="#basicAccordion"
                style={{}}
              >
                <div className="accordion-body">
                  No, individual entrepreneurs are not eligible to apply for
                  funding. Only DPIIT recognized startups can apply for the seed
                  funding.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingFive">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-mdb-toggle="collapse"
                  data-mdb-target="#basicAccordionCollapseFive"
                  aria-expanded="false"
                  aria-controls="collapseFive"
                >
                  Does the seed funding avalable for the startups from specific
                  sectors only ?
                </button>
              </h2>
              <div
                id="basicAccordionCollapseFive"
                className="accordion-collapse collapse"
                aria-labelledby="headingFive"
                data-mdb-parent="#basicAccordion"
                style={{}}
              >
                <div className="accordion-body">
                  No , startups from any sector can apply for the funding .
                  However the preference would be given to startups creating
                  innovative solutions in sectors such as social impact , waste
                  management , water management , finacial inclusion , education
                  , agriculture , food processing , biotechnology , healthcare
                  etc.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingFour">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-mdb-toggle="collapse"
                  data-mdb-target="#basicAccordionCollapseFour"
                  aria-expanded="false"
                  aria-controls="collapseFour"
                >
                  Are there any minimum education qualification criteria for the
                  founders to apply for funding ?
                </button>
              </h2>
              <div
                id="basicAccordionCollapseFour"
                className="accordion-collapse collapse"
                aria-labelledby="headingFour"
                data-mdb-parent="#basicAccordion"
                style={{}}
              >
                <div className="accordion-body">
                  There is no minimum education qualification required for
                  founders to apply for funding .
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FaqSection;
