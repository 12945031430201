import React from "react";
import HeaderNew from "./HeaderNew";
import NewsDetail from "./NewsDetail";

const NewsEventDetail = () => {
  return (
    <>
      <HeaderNew />
      <NewsDetail />
    </>
  );
};

export default NewsEventDetail;
