import React from "react";

const AdminProfile = () => {
  return (
    <div>
      <h1 className="text-center display-1 fw-bold p-5">Admin Profile</h1>
    </div>
  );
};

export default AdminProfile;
