import React from "react";
import { Link } from "react-router-dom";

const ObjectiveDetail = () => {
  return (
    <>
      <div
        className="flex flex-col md:flex-row items-center justify-center p-3 md:mt-5 -ml-12 md:-ml-36"
        style={{ backgroundColor: "white" }}
      >
        <div className="col-md-4 ml-16 md:ml-0 mb-3">
          <img src="/objective.png" alt="" style={{ width: "60%" }} />
        </div>
        <div className="ml-12 md:ml-0 mt-3 md:mt-0">
          <p className="py-2 text-black font-semibold text-4xl ml-4 md:ml-0">
            Raise funding more effectively
          </p>

          <p className="py-2 -ml-12 text-black text-xl ml-4 md:ml-0">
            ‘Raising capital can be so difficult without the network.
            <br className="hidden sm:block"></br>
            Not anymore!<br className="hidden sm:block"></br>
            We make it easier for founders to grow their network &{" "}
            <br className="hidden sm:block"></br>
            make fundraising more easy going’
            <br className="hidden sm:block"></br>
          </p>
        </div>
      </div>
      <div className="flex flex-col md:flex-row justify-center p-3 md:mt-6 md:ml-12">
        <div className="col-md-4 ml-3 sm:hidden">
          <img src="/need.png" alt="" className="h-64 w-[60%]" />
        </div>
        <div className="md:mr-32 md:mt-16 mt-3 ml-4 md:ml-0">
          <h2 className="font-semibold text-black text-4xl py-3">
            Leverage your network
          </h2>
          <p className="py-2 text-black text-xl">
            ‘Our aim is to amplify using network effects.{" "}
            <br className="hidden sm:block"></br>
            Anyone who has access to investors can join us as a scout to{" "}
            <br className="hidden sm:block"></br>
            help founders! We simply increase the chances for startups to{" "}
            <br className="hidden sm:block"></br>
            raise funds’
          </p>
          <Link
            className="btn normal-case font-bold mt-8 text-sm"
            style={{ backgroundColor: "#b0ff4b", color: "black" }}
            to="/main/signup"
          >
            Free Signup
          </Link>
        </div>
        <div className="col-md-4 ml-3 hidden sm:block">
          <img src="/need.png" alt="" style={{ width: "60%" }} />
        </div>
      </div>
    </>
  );
};

export default ObjectiveDetail;
