import { Button, Card, CardContent, TextField } from "@mui/material";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useState } from "react";

import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as Yup from "yup";
import app_config from "../../config";
import HeaderNew from "./HeaderNew";

const ResetPassword = () => {
  const navigate = useNavigate();

  const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid Email Id")
      .required("Email Id is required"),
  });
  const formInitialValue = {
    email: "",
  };

  const requestResetPassword = async (values) => {
    try {
      let response = await fetch(
        `${app_config.apiurl}/users/request-pwd-reset/${values.email}`,
      );
      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "A password rest link is sent to your email. Please check your inbox.",
        }).then(() => navigate("/main/login"));
      } else {
        let errorResponse = await response.json();
        let errorMessage =
          errorResponse.error ||
          "Unable to process your request. Please try again later";
        Swal.fire({
          icon: "error",
          title: "Error",
          text: errorMessage,
        });
      }
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Unable to process your request. Please try again later",
      });
    }
  };

  return (
    <>
      <HeaderNew />
      <div className="reset-card" align="center">
        <Card className="mt-5" sx={{ width: 451 }} align="center">
          <h1 className="text-3xl py-3">Forgot Password</h1>
          <CardContent align="center">
            <Formik
              initialValues={formInitialValue}
              validationSchema={forgotPasswordSchema}
              onSubmit={requestResetPassword}
            >
              <Form>
                <Field
                  name="email"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className="w-100 mt-3"
                      variant="outlined"
                      placeholder="Enter Your Email"
                    />
                  )}
                />
                <ErrorMessage className="text-red" name="email" />

                <Button
                  variant="contained"
                  className="mt-3"
                  type="submit"
                  fullWidth
                  style={{ color: "white", background: "#b0ff4b" }}
                >
                  Reset
                </Button>
              </Form>
            </Formik>
          </CardContent>
        </Card>
        <hr className="mt-5" />
      </div>
    </>
  );
};

export default ResetPassword;
