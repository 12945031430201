import { Formik } from "formik";
import { MDBInput } from "mdb-react-ui-kit";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as Yup from "yup";
import app_config from "../../config";
import { useStartupContext } from "../../context/StartupProvider";
import HeaderNew from "./HeaderNew";

const Signin = () => {
  const { setSloggedIn } = useStartupContext();

  // signin schema validation
  const SigninSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is required"),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .required("Password is required"),
  });
  ///////////////////////////////
  const navigate = useNavigate();

  const userSubmit = async (formdata, { setSubmitting }) => {
    console.log(formdata);
    setSubmitting(true);
    const res = await fetch(`${app_config.apiurl}/users/auth`, {
      method: "POST",
      body: JSON.stringify(formdata),
      headers: { "Content-Type": "application/json" },
    });

    console.log(res.status);
    setSubmitting(false);
    const data = await res.json();
    if (res.status === 200) {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "You have logged in successfully",
      }).then(() => handleLoginSuccess(data));
    } else {
      let errorMessage =
        data.error || "Unable to process your request. Please try again later.";
      Swal.fire({
        icon: "error",
        title: "Error",
        text: errorMessage,
      });
    }
  };

  const handleLoginSuccess = async (response) => {
    let role = response.result.role;
    sessionStorage.setItem(role, JSON.stringify(response.result));
    sessionStorage.setItem("token", response.token);
    if (role === "investor") {
      navigate("/investor/startuplist");
    } else {
      setSloggedIn(true);
      navigate("/startup/investorlist");
    }
  };

  return (
    <div style={{ height: "90vh" }}>
      <HeaderNew />
      <div
        className="row"
        style={{ height: "40vh", backgroundColor: "#b0ff4b" }}
      ></div>
      <div
        className="row mx-auto"
        style={{ marginTop: "-250px", width: "fit-content" }}
      >
        <div
          className="card pt-4"
          style={{ height: "fit-content", padding: "40px" }}
        >
          <div className="card-body">
            <p className="text-center h4">Login</p>
            <hr />

            <Formik
              initialValues={{ email: "", password: "" }}
              validationSchema={SigninSchema} //Validation Schema
              onSubmit={userSubmit}
            >
              {({
                values,
                handleSubmit,
                handleChange,
                isSubmitting,
                errors,
                touched,
              }) => (
                <form onSubmit={handleSubmit}>
                  {/* Email input */}
                  <div className="form-outline mb-4">
                    <MDBInput
                      label="Email"
                      type="email"
                      value={values.email}
                      onChange={handleChange}
                      name="email"
                    />
                    {errors.email && touched.email ? (
                      <div>{errors.email}</div>
                    ) : null}
                  </div>

                  {/* Password input */}
                  <div className="form-outline mb-4">
                    <MDBInput
                      label="Password"
                      type="password"
                      value={values.password}
                      onChange={handleChange}
                      name="password"
                    />
                    {errors.password && touched.password ? (
                      <div>{errors.password}</div>
                    ) : null}
                  </div>
                  <button
                    disabled={isSubmitting}
                    type="submit"
                    className="btn btn-block mb-4"
                    style={{ backgroundColor: "#b0ff4b", color: "black" }}
                  >
                    {isSubmitting ? (
                      <span
                        class="spinner-border spinner-border-sm"
                        aria-hidden="true"
                      ></span>
                    ) : (
                      "Login"
                    )}
                  </button>

                  <div className="row">
                    <div class="d-flex align-items-center justify-content-center">
                      <a href="/main/forgot">Forgot password?</a>
                    </div>

                    <div class=" d-flex align-items-center justify-content-center">
                      <a href="/main/signup">New User? Create Account</a>
                    </div>
                  </div>

                  <div className="d-flex align-items-center justify-content-center">
                    {/*google button*/}
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signin;
