import React from "react";
import HeaderNew from "./HeaderNew";
import NewsBrowser from "./NewsBrowser";

const NewsEvents = () => {
  return (
    <>
      <HeaderNew />
      <NewsBrowser />
    </>
  );
};

export default NewsEvents;
